import { List } from "./common/list"

export const Sidebar = () => {
  return (
    <div className="w-[300px] md:w-[382px] h-full fixed top-0 z-20 left-[-260px] md:left-[-342px] hover:left-0 transition-all ease duration-500 text-left">
      <div className="bg-cc-yellow w-[260px] md:w-[340px] drop-shadow-2xl h-full rounded-r-[40px] text-sm">
        <div className=" w-4/5 mx-auto flex flex-col justify-between h-full pt-20 pb-4">
          <div className="grid gap-y-6">
            <h1 className="text-xl font-bold font-playfair">
              Cardcipe lasts a little bit longer than your usual namecard.
            </h1>
            <List type="number" items={howTo} />
            <h2 className="text-lg font-bold font-playfair">🐓 Use Cases</h2>
            <List type="none" items={useCase} />
          </div>
          <div>
            <p className="font-bold">
              Got a receipe 🍔 to add?{" "}
              <a
                className="text-[#0014FF]"
                href="https://docs.google.com/forms/d/e/1FAIpQLSfpB8F3PLPkckMQNIyz9eAHTSILFguLSpsdQHClQWvmgwnkIA/viewform"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Help us out!
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="absolute right-0 bg-black text-white bottom-16 w-10 h-10 rounded-r-lg cursor-pointer text-center">
        <p className="text-[26px]">?</p>
      </div>
    </div>
  )
}

const howTo = [
  "Fill in the blanks (don't worry, your details never leave your computer)",
  "Shuffle until you find a recipe you like",
  "Generate a ready-to-print PDF",
]

const useCase = [
  "“Avoid awkward card exchanges at networking events”",
  "“Use it as a prop along with a pathetic pickup line”",
  "“Last minute groceries list”",
  "“A good reason to not feel bad when your card is thrown away,blame it on the recipe”",
]
