import html2canvas from "html2canvas"
import jsPDF from "jspdf"

// Namecard dimensions in mm
const CARD_WIDTH = 89
const CARD_HEIGHT = 51

// X and Y position in mm for each card in PDF file
const X_POSITIONS = [14, 107]
const Y_POSITIONS = [13, 68, 123, 178, 233]

function positionCards(doc: jsPDF, card: HTMLCanvasElement) {
  for (const x of X_POSITIONS) {
    for (const y of Y_POSITIONS) {
      doc.addImage(card, "JPEG", x, y, CARD_WIDTH, CARD_HEIGHT)
    }
  }
}

export const generateCard = async () => {
  const cardFrontDiv = document.getElementById("card-front")
  const cardBackDiv = document.getElementById("card-back")
  if (!cardBackDiv || !cardFrontDiv) return

  const inputName = document.getElementById("input-name")
  const divName = document.getElementById("div-name")
  if (inputName) {
    inputName.style.display = "none"
  }
  if (divName) {
    divName.style.display = "inline-block"
  }

  const doc = new jsPDF()
  doc.setFont("times", "bold")
  doc.setFontSize(12)

  await html2canvas(cardBackDiv, { scale: 4 }).then((canvas) => {
    positionCards(doc, canvas)
  })

  doc.text("cardcipe.io", 187, 293)
  doc.addPage()

  await html2canvas(cardFrontDiv, { scale: 4 }).then((canvas) => {
    positionCards(doc, canvas)
  })
  // TODO: Hack - reset display
  if (inputName) inputName.style.display = "initial"
  if (divName) divName.style.display = "none"

  doc.text("cardcipe.io", 187, 293)
  doc.save("namecard.pdf")
}
