import { Product } from "@chec/commerce.js/types/product"
import { useState, useEffect } from "react"
import { commerce } from "../lib/commerce"

export const useGetProducts = () => {
  const [products, setProducts] = useState<Product[]>([])

  const fetchProducts = () => {
    commerce.products
      .list({ include: "variant_groups" })
      .then((products) => {
        setProducts(products.data)
      })
      .catch((error) => {
        console.error("There was an error fetching the products", error)
      })
  }

  useEffect(() => {
    if (!products.length) fetchProducts()
  }, [products])

  const cardcipe = products.find((p) => p.id === "prod_4WJvlKRRBJ5bYV")

  return { products, cardcipe }
}
