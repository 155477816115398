import { CartInput } from "../common/cart-input"
import { FormProps } from "../types"
import { pageCount, pages } from "./checkout-form"
import { CartFooter } from "./contact-details-form"

type FormPropsShipping = FormProps & {
  handleFormSelectChanges: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

export const ShippingAddressForm: React.FC<FormPropsShipping> = ({
  checkoutData,
  handleFormChanges,
  handleFormSelectChanges,
  onNext,
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onNext()
  }

  const {
    shippingStreet,
    shippingPostalZipCode,
    shippingCountry,
    shippingStateProvince,
    shippingCity,
    shippingCountries,
    shippingSubdivisions,
  } = checkoutData

  const formIsIncomplete =
    !shippingStreet ||
    !shippingPostalZipCode ||
    !shippingCountry ||
    !shippingStateProvince ||
    !shippingCity ||
    !shippingCountries ||
    !shippingSubdivisions

  return (
    <div>
      <div className="grid gap-y-2 h-full">
        <h3 className="text-lg font-bold">Shipping Address</h3>
        <form className="flex flex-col justify-between" onSubmit={handleSubmit}>
          <div className="grid md:grid-cols-2 gap-x-6 gap-y-1">
            <div className="md:col-span-2 grid gap-y-1">
              <label className="text-gray-600" htmlFor="shippingStreet">
                Street address
              </label>
              <CartInput
                required
                type="text"
                name="shippingStreet"
                value={shippingStreet}
                onChange={handleFormChanges}
              />
            </div>
            <div className="grid gap-y-1">
              <label className="text-gray-600" htmlFor="shippingCountry">
                Country / Region
              </label>
              <select
                name="shippingCountry"
                required
                className="w-full h-9 px-3 py-2 rounded-md border border-cc-lightgray shadow-sm"
                value={shippingCountry ?? "loading"}
                onChange={handleFormSelectChanges}
              >
                {shippingCountry ? (
                  <>
                    <option value="default" disabled>
                      Select a country
                    </option>
                    {Object.keys(shippingCountries).map((index) => (
                      <option value={index} key={index}>
                        {shippingCountries[index]}
                      </option>
                    ))}
                  </>
                ) : (
                  <option value="loading">Loading...</option>
                )}
              </select>
            </div>
            <div className="grid gap-y-1">
              <label className="text-gray-600" htmlFor="shippingStateProvince">
                State / Province
              </label>
              <select
                className="w-full h-9 px-3 py-2 rounded-md border border-cc-lightgray shadow-sm"
                required
                name="shippingStateProvince"
                value={shippingStateProvince ?? "loading"}
                onChange={handleFormSelectChanges}
                disabled={!shippingSubdivisions}
              >
                {shippingStateProvince ? (
                  Object.keys(shippingSubdivisions).map((index) => {
                    return (
                      <option value={index} key={index}>
                        {shippingSubdivisions[index]}
                      </option>
                    )
                  })
                ) : (
                  <option value="loading">Loading...</option>
                )}
              </select>
            </div>
            <div className="grid gap-y-1">
              <label className="text-gray-600" htmlFor="shippingCity">
                City
              </label>
              <CartInput
                required
                type="text"
                name="shippingCity"
                value={shippingCity}
                onChange={handleFormChanges}
              />
            </div>
            <div className="grid gap-y-1">
              <label className="text-gray-600" htmlFor="shippingPostalZipCode">
                ZIP / Postal
              </label>
              <CartInput
                required
                type="text"
                name="shippingPostalZipCode"
                value={shippingPostalZipCode}
                onChange={handleFormChanges}
              />
            </div>
          </div>
          <CartFooter
            index={1}
            disabled={formIsIncomplete}
            label={pages[1]}
            pageCount={pageCount}
          />
        </form>
      </div>
    </div>
  )
}
