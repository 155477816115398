import ItalyIcon from "../images/flag/it.svg"
import ChinaIcon from "../images/flag/cn.svg"
import UkIcon from "../images/flag/uk.svg"
import ThailandIcon from "../images/flag/th.svg"
import VietnamIcon from "../images/flag/vn.svg"

const countryToIconMap = {
  Italy: ItalyIcon,
  China: ChinaIcon,
  UK: UkIcon,
  Thailand: ThailandIcon,
  Vietnam: VietnamIcon,
}
const RECIPES = [
  {
    dishName: "Carbonara",
    recipe1: [
      "100g pancetta",
      "50g pecorino cheese",
      "50g parmesan",
      "2 plump garlic cloves, peeled and left whole",
    ],
    recipe2: [
      "Sea salt and freshly grated black pepper",
      "50g unsalted butter",
      "Glass of red wine",
    ],
    countryOfOrigin: "Italy",
  },
  {
    dishName: "Pizza Napoletana",
    recipe1: [
      "400 grams of pizza dough",
      "5-6 tomatoes",
      "Dried oregano",
      "Basil leaves",
    ],
    recipe2: [
      "Garlic cloves",
      "Extra virgin olive oil",
      "Glass of red wine",
      "Italian flag",
    ],
    countryOfOrigin: "Italy",
  },
  {
    dishName: "Tiramisu",
    recipe1: [
      "6 egg yolks",
      "¾ cup white sugar",
      "Milk and heavy cream",
      "Vanilla extract and rum",
    ],
    recipe2: [
      "1 pound mascarpone cheese",
      "¼ cup strong brewed coffee, room temperature",
      "ladyfinger cookies",
      "Unsweetened cocoa powder",
    ],
    countryOfOrigin: "Italy",
  },
  {
    dishName: "Peking Duck",
    recipe1: [
      "1 whole duck, dressed",
      "Ground cinnamon",
      "Ground ginger",
      "Ground nutmeg",
    ],
    recipe2: [
      "Ground white pepper",
      "Ground cloves",
      "Soy sauce",
      "Pairs of chopsticks",
    ],
    countryOfOrigin: "China",
  },

  {
    dishName: "Red Pork Belly",
    recipe1: [
      "500g pork belly",
      "2 cups Shaoxing rice wine",
      "light soy sauce",
      "dark soy sauce",
    ],
    recipe2: ["5 slices ginger", "2 star-anise", "1 bay leaf", "Rock sugar"],
    countryOfOrigin: "China",
  },
  {
    dishName: "Hainan Chicken Rice",
    recipe1: ["1 whole chicken", "Ginger", "Scallions", "4 cloves of garlic"],
    recipe2: [
      "Jasmine white rice",
      "Ginger-Garlic Sauce",
      "Sweet Dark Soy sauce",
      "Chili Sauce",
    ],
    countryOfOrigin: "China",
  },
  {
    dishName: "Yangzhou Fried Rice",
    recipe1: [
      "Day-old cooked Jasmine rice",
      "Shiitake mushroom",
      "Spring onions and garlic",
      "Chinese-style ham",
    ],
    recipe2: [
      "Carrot",
      "Green peas",
      "Cooked Chicken thigh and egg",
      "Raw prawns, peeled",
    ],
    countryOfOrigin: "China",
  },
  {
    dishName: "Dumplings",
    recipe1: [
      "500g of flour",
      "Red cabbage",
      "Carrot and Green onion",
      "6 cloves of garlic",
    ],
    recipe2: [
      "Minced ginger",
      "Soy sauce, sesame oil",
      "Ground pork, Peeled shrimp",
      "Mushroom",
    ],
    countryOfOrigin: "China",
  },
  {
    dishName: "Tikka Masala",
    recipe1: [
      "Basmati rice",
      "Boneless chicken thigh",
      "Sweet onion",
      "Tomato paste and sauce",
    ],
    recipe2: [
      "Garlic cloves",
      "Grated ginger, garam masala, chili powder, ground turmeric",
      "1 cup chicken stock",
      "½ cup heavy cream",
    ],
    countryOfOrigin: "UK",
  },
  {
    dishName: "Pad Thai",
    recipe1: [
      "flat rice noodles",
      "Minced garlic",
      "shrimp, chicken and extra-firm tofu",
      "2 eggs",
    ],
    recipe2: [
      "1 cup fresh bean sprouts",
      "green onions and red bell pepper",
      "½ cup dry roasted peanuts",
      "2 limes",
    ],
    countryOfOrigin: "Thailand",
  },
  {
    dishName: "Pho",
    recipe1: [
      "Flat rice noodle",
      "Mixed beef bones, shin, knuckle, marrow bones",
      "Ginger, garlic, thai basil, lime, cinnamon",
      "yellow and red onion",
    ],
    recipe2: [
      "Black peppercorn and 6 whole star anise",
      "Beef brisket, Boneless sirloin steak",
      "Fish sauce",
      "Bean sprouts",
    ],
    countryOfOrigin: "Vietnam",
  },
]

export const recipes = RECIPES.map((recipe) => ({
  ...recipe,
  // @ts-ignore
  countryOfOrigin: countryToIconMap[recipe.countryOfOrigin],
}))
