import { Cart } from "@chec/commerce.js/types/cart"
import { CheckoutData } from "../cart"
import { ContactDetailsForm } from "./contact-details-form"
import { PaymentForm } from "./payment"
import { ShippingAddressForm } from "./shipping-address-form"
import { ThankYouPage } from "./thank-you"

type CheckoutFormProps = {
  index: number
  setIndex: React.Dispatch<React.SetStateAction<number>>
  setShowCart: React.Dispatch<React.SetStateAction<boolean>>
  checkoutData: CheckoutData
  setCheckoutData: React.Dispatch<React.SetStateAction<CheckoutData>>
  cart: Cart
  setCart: React.Dispatch<React.SetStateAction<Cart | undefined>>
  checkoutTokenId: string
}

export const pages = [
  "Next: Shipping address",
  "Next: Payment",
  "Confirm",
  "Back to Home",
]

export const pageCount = pages.length

export const CheckoutForm: React.FC<CheckoutFormProps> = ({
  index,
  setIndex,
  checkoutData,
  setShowCart,
  setCheckoutData,
  cart,
  setCart,
  checkoutTokenId,
}) => {
  const handleFormInputChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckoutData((checkoutData) => ({
      ...checkoutData,
      [e.target.name]: e.target.value,
    }))
  }

  const handleFormSelectChanges = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCheckoutData((checkoutData) => ({
      ...checkoutData,
      [e.target.name]: e.target.value,
    }))
  }

  return (
    <div>
      {index === 0 && (
        <ContactDetailsForm
          onNext={() => setIndex((index) => index + 1)}
          checkoutData={checkoutData}
          handleFormChanges={handleFormInputChanges}
        />
      )}
      {index === 1 && (
        <ShippingAddressForm
          onNext={() => setIndex((index) => index + 1)}
          checkoutData={checkoutData}
          handleFormChanges={handleFormInputChanges}
          handleFormSelectChanges={handleFormSelectChanges}
        />
      )}
      {index === 2 && (
        <PaymentForm
          onNext={() => setIndex((index) => index + 1)}
          setCart={setCart}
          lineItems={cart?.line_items ?? []}
          checkoutTokenId={checkoutTokenId}
          checkoutData={checkoutData}
        />
      )}
      {index === 3 && <ThankYouPage setShowCart={setShowCart} />}
    </div>
  )
}
