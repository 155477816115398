import { GetShippingOptionsResponse } from "@chec/commerce.js/features/checkout"
import {
  LocaleListCountriesResponse,
  LocaleListSubdivisionsResponse,
} from "@chec/commerce.js/features/services"
import { Cart as ChecCart } from "@chec/commerce.js/types/cart"
import { CheckoutToken } from "@chec/commerce.js/types/checkout-token"
import { useEffect, useState } from "react"
import cardcipePlaceholder from "../images/cardcipe-placeholder.png"
import { commerce } from "../lib/commerce"
import { CheckoutForm } from "./checkout-form/checkout-form"
import { CartInput } from "./common/cart-input"

type CartProps = {
  setShowCart: React.Dispatch<React.SetStateAction<boolean>>
  cart: ChecCart | undefined
  setCart: React.Dispatch<React.SetStateAction<ChecCart | undefined>>
}

export type CheckoutData = {
  // Customer details
  firstName: string
  lastName: string
  email: string
  // Shipping details
  shippingStreet: string
  shippingCity: string
  shippingStateProvince: string
  shippingPostalZipCode: string
  shippingCountry: string
  // Payment details
  billingPostalZipCode: string
  // Shipping & Fulfillment data
  shippingCountries: LocaleListCountriesResponse["countries"]
  shippingSubdivisions: LocaleListSubdivisionsResponse["subdivisions"]
  shippingOptions: GetShippingOptionsResponse[]
  shippingOption: GetShippingOptionsResponse | undefined
}

export const Cart: React.FC<CartProps> = ({ setShowCart, cart, setCart }) => {
  const line_items = cart?.line_items ?? []
  const cardcipe = line_items[0]

  const [index, setIndex] = useState(0)

  // customer, shipping, fulfillment, payment
  const [checkoutData, setCheckoutData] = useState<CheckoutData>({
    // Customer details
    firstName: "",
    lastName: "",
    email: "",
    // Shipping details
    shippingStreet: "",
    shippingCity: "",
    shippingStateProvince: "",
    shippingPostalZipCode: "",
    shippingCountry: "",
    // Payment details
    billingPostalZipCode: "123",
    // Shipping & Fulfillment data
    shippingCountries: {},
    shippingSubdivisions: {},
    shippingOptions: [],
    shippingOption: undefined,
  })

  // checkout
  // TODO: Check if checkoutToken needs to be object. If not, remove object
  const [checkoutToken, setCheckoutToken] = useState<{
    checkoutToken: CheckoutToken | undefined
  }>({ checkoutToken: undefined })

  const generateCheckoutToken = () => {
    if (line_items.length && !checkoutToken.checkoutToken) {
      commerce.checkout
        .generateToken(cart!.id, { type: "cart" })
        .then((token) => {
          setCheckoutToken({ checkoutToken: token })
          return token
        })
        .then((token) => {
          fetchShippingCountries(token.id)
        })
        .catch((error) => {
          console.error("There was an error in generating a token", error)
        })
    }
  }

  useEffect(() => {
    generateCheckoutToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  // fetch shipping countries
  const fetchShippingCountries = (checkoutTokenId: string) => {
    commerce.services
      .localeListShippingCountries(checkoutTokenId)
      .then((countries) => {
        const firstKey = Object.keys(countries.countries)[0]
        setCheckoutData((checkoutData) => ({
          ...checkoutData,
          shippingCountries: countries.countries,
          shippingCountry: firstKey,
        }))
        return firstKey
      })
      .then((countryCode) => {
        fetchSubdivisions(countryCode)
      })
      .catch((error) => {
        console.error(
          "There was an error fetching a list of shipping countries",
          error
        )
      })
  }

  // fetch subdivisions (provinces /states)
  const fetchSubdivisions = (countryCode: string) => {
    commerce.services
      .localeListSubdivisions(countryCode)
      .then((subdivisions) => {
        const firstKey = Object.keys(subdivisions.subdivisions)[0]
        setCheckoutData((checkoutData) => ({
          ...checkoutData,
          shippingSubdivisions: subdivisions.subdivisions,
          shippingStateProvince: firstKey,
        }))
        return { divisionCode: firstKey, countryCode }
      })
      .then(({ divisionCode, countryCode }) => {
        fetchShippingOptions(
          checkoutToken.checkoutToken!.id,
          countryCode,
          divisionCode
        )
      })
      .catch((error) => {
        console.error("There was an error fetching the subdivisions", error)
      })
  }

  // fetch shipping options
  // TODO: Default set shipping option as international shipping
  // This should also reflect in shipping fees & total
  const fetchShippingOptions = (
    checkoutTokenId: string,
    country: string, // needs to be country code
    stateProvince: string | undefined = undefined // subdivision code
  ) => {
    commerce.checkout
      .getShippingOptions(checkoutTokenId, {
        country: country,
        region: stateProvince,
      })
      .then((options) => {
        const shippingOption = options[0] || null

        setCheckoutData((checkoutData) => ({
          ...checkoutData,
          shippingOptions: options,
          shippingOption: shippingOption,
        }))
      })
      .catch((error) => {
        console.error("There was an error fetching the shipping methods", error)
      })
  }

  // fetch and set shipping options on country change
  useEffect(() => {
    if (checkoutToken.checkoutToken) {
      fetchShippingOptions(
        checkoutToken.checkoutToken?.id,
        checkoutData.shippingCountry
      )
    }

    if (checkoutData.shippingCountry) {
      fetchSubdivisions(checkoutData.shippingCountry)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutData.shippingCountry])

  return (
    <div className="fixed top-0 right-0 h-full w-[339px] font-inter shadow-xl">
      <div className="h-full border-8 border-white rounded-l-[50px] bg-white p-5 pt-10 pb-16 md:pb-4 text-sm grid gap-y-3 overflow-auto">
        <h2 className="text-lg font-bold font-playfair">Your cart</h2>
        <div className="flex justify-between">
          <img
            className="w-32 h-32"
            alt="box of namecards"
            src={cardcipe?.image?.url ?? cardcipePlaceholder}
          />
          <div className="grid gap-y-1">
            <p className="text-base font-semibold">Box of 100</p>
            <p className="text-gray-500">{cardcipe?.quantity} box</p>
            <p className="text-gray-500">100 pieces per box</p>
            <p className="font-semibold text-base">
              {cardcipe?.line_total.formatted_with_symbol} USD
            </p>
          </div>
        </div>

        {index !== 3 && (
          <div className="grid gap-y-1">
            <label htmlFor="promo-code" className="font-medium text-gray-600">
              Promo Code (Optional)
            </label>
            <div className="flex justify-between gap-x-2 ">
              <CartInput id="promo-code" />
              <button className="bg-cc-darkgray h-10 text-white font-medium px-5 py-2 rounded-md">
                Apply
              </button>
            </div>
          </div>
        )}

        <div className="flex flex-col gap-3 my-5 text-sm">
          <div className="flex justify-between">
            <div className="text-gray-500">Subtotal</div>
            <div className="font-bold">
              {cart?.subtotal.formatted_with_symbol}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-gray-500">Discount</div>
            <div className="font-bold">None</div>
          </div>
          <div className="flex justify-between">
            <div className="text-gray-500">Shipping</div>
            <div className="font-bold">
              $
              {checkoutData.shippingOption?.price.formatted_with_code ??
                "Loading..."}
            </div>
          </div>
        </div>
        <hr />
        <div className="flex justify-between">
          <div className="text-sm text-gray-500">Total</div>
          <div className="font-bold text-lg">
            $
            {checkoutData.shippingOption && cart
              ? (
                  checkoutData.shippingOption.price.raw + cart.subtotal.raw
                ).toFixed(2)
              : "Loading..."}{" "}
            USD
          </div>
        </div>
        <CheckoutForm
          cart={cart!}
          setCart={setCart}
          setCheckoutData={setCheckoutData}
          checkoutData={checkoutData}
          setShowCart={setShowCart}
          index={index}
          setIndex={setIndex}
          checkoutTokenId={checkoutToken.checkoutToken?.id ?? ""}
        />
      </div>
    </div>
  )
}
