import ThankYouImage from "../../images/thank_you.png"
import { NextButton } from "../common/next-button"

type ThankYouPageProps = {
  setShowCart: React.Dispatch<React.SetStateAction<boolean>>
}

export const ThankYouPage: React.FC<ThankYouPageProps> = ({ setShowCart }) => {
  const customerReference = JSON.parse(
    window.sessionStorage.getItem("order_receipt") ?? ""
  ).id

  return (
    <div>
      <div className="flex flex-col gap-9 items-center">
        <h3 className="font-playfair text-lg font-bold">
          Thank you for your purchase!
        </h3>
        <img
          src={ThankYouImage}
          alt="person in yellow waving"
          width="214px"
          height="171px"
        />
        <div className="text-center w-64 text-gray-500">
          <p>Your order has been completed successfully! </p>
          <p>
            Here is your order number for reference:{" "}
            <span className="font-bold">{customerReference}</span>
          </p>
        </div>
      </div>
      <div className="col-span-2 flex flex-col items-center gap-4 rounded-bl-[33px] mt-10">
        <NextButton
          onClick={() => {
            setShowCart(false)
          }}
        >
          Back to Home
        </NextButton>
      </div>
    </div>
  )
}
