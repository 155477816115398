type Props = {
  activeIndex: number
  pageCount: number
}

export const CirclePageIndicator: React.FC<Props> = ({
  activeIndex,
  pageCount,
}) => {
  const pages = Array(pageCount).fill(null)

  return (
    <div className="flex items-center gap-x-5">
      {pages.map((_, index) => (
        <div
          key={index}
          className={`w-5 h-5 rounded-full flex items-center justify-center ${
            activeIndex === index ? "bg-[#fde68a]" : ""
          } `}
        >
          <div
            className={`w-2 h-2 rounded-full bg-[#f59e0b] ${
              activeIndex < index ? "bg-[#e5e7eb]" : ""
            }`}
          />
        </div>
      ))}
    </div>
  )
}
