import profileJf from "../images/profile_jf.svg"
import profileSY from "../images/profile_sy.svg"
import { useState } from "react"

export const Footer = () => {
  return (
    <footer className="flex justify-end z-10 px-6 py-4 text-sm font-bold fixed bottom-0 w-full">
      <span>
        Made by{" "}
        <Author
          image={profileJf}
          linkToProfile="https://www.notion.so/yeapjf-me-f8c9ae0b9e6147e9b7aa28d024c685ef"
          name="JF Yeap"
        />
        and{" "}
        <Author
          image={profileSY}
          linkToProfile="https://seanyeoh.com/"
          name="Sean Y"
        />
      </span>
    </footer>
  )
}

type AuthorProps = {
  name: string
  linkToProfile: string
  image: string
}

const Author: React.FC<AuthorProps> = ({ name, linkToProfile, image }) => {
  const [hover, setHover] = useState(false)

  return (
    <span
      className="relative"
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      {hover && (
        <img
          className="absolute left-2 bottom-6 w-20 h-20"
          alt={name}
          src={image}
        />
      )}
      <a
        href={linkToProfile}
        target="_blank"
        rel="noreferrer"
        className="underline text-[#0014FF]"
      >
        {name}{" "}
      </a>
    </span>
  )
}
