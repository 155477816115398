import { CartInput } from "../common/cart-input"
import { CirclePageIndicator } from "../common/circle-page-indicator"
import { FormProps } from "../types"
import { pageCount, pages } from "./checkout-form"
import { NextButton } from "../common/next-button"

export const ContactDetailsForm: React.FC<FormProps> = ({
  onNext,
  checkoutData,
  handleFormChanges,
}) => {
  const { firstName, lastName, email } = checkoutData

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onNext()
  }

  const formIsIncomplete = !firstName || !lastName || !email

  return (
    <div className="overflow-auto">
      <div className="grid gap-y-2 h-full">
        <h3 className="text-lg font-bold font-playfair">Contact details</h3>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col justify-between text-sm"
        >
          <div className="grid md:grid-cols-2 gap-x-6 gap-y-1">
            <div className="grid gap-y-1">
              <label className="text-gray-600" htmlFor="firstName">
                First name
              </label>
              <CartInput
                required
                name="firstName"
                type="text"
                value={firstName}
                placeholder="Enter your first name"
                onChange={handleFormChanges}
              />
            </div>
            <div className="grid gap-y-1">
              <label className="text-gray-600" htmlFor="lastName">
                Last name
              </label>
              <CartInput
                required
                name="lastName"
                type="text"
                value={lastName}
                placeholder="Enter your last name"
                onChange={handleFormChanges}
              />
            </div>
            <div className="md:col-span-2 grid gap-y-1">
              <label className="text-gray-600" htmlFor="email">
                Email address
              </label>
              <CartInput
                required
                name="email"
                value={email}
                placeholder="Enter your email"
                type="email"
                onChange={handleFormChanges}
                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
              />
            </div>
          </div>
          <CartFooter
            index={0}
            disabled={formIsIncomplete}
            label={pages[0]}
            pageCount={pageCount}
          />
        </form>
      </div>
    </div>
  )
}

type Props = {
  disabled?: boolean
  label: string
  pageCount: number
  index: number
}
export const CartFooter: React.FC<Props> = ({
  disabled,
  index,
  label,
  pageCount,
}) => {
  return (
    <div className="col-span-2 flex flex-col items-center gap-4 rounded-bl-[33px] mt-10">
      <CirclePageIndicator activeIndex={index} pageCount={pageCount} />
      <NextButton
        className="disabled:bg-stone-700"
        type="submit"
        disabled={disabled}
      >
        {label}
      </NextButton>
    </div>
  )
}
