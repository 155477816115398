type Props = {
  items: string[]
  type: "number" | "none"
}

export const List: React.FC<Props> = ({ items, type }) => {
  return (
    <ol className={`${type === "number" ? "list-decimal" : ""} grid gap-y-4`}>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ol>
  )
}
