type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export const CardInput: React.FC<InputProps> = ({
  type,
  value,
  onChange,
  placeholder,
  className,
  ...props
}) => {
  return (
    <input
      className={`text-center bg-transparent outline-none placeholder:opacity-30 font-mono w-full ${className}`}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      {...props}
    />
  )
}
