import { PropsWithChildren } from "react"

type CircleProps = PropsWithChildren &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    changeOnHover?: boolean
  }

export const Circle: React.FC<CircleProps> = ({
  children,
  className,
  changeOnHover = false,
}) => {
  return (
    <div
      className={`border-4 rounded-full bg-black duration-300 text-white border-white w-[60px] h-[60px] ${
        changeOnHover ? "hover:scale-110" : ""
      } ${className}`}
    >
      {children}
    </div>
  )
}
