import { Content } from "./components/content"
import { Footer } from "./components/footer"
import { Marquee } from "./components/marquee"
import { Sidebar } from "./components/sidebar"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  throw new Error("Missing REACT_APP_STRIPE_PUBLISHABLE_KEY")
}

// Call `loadStripe` outside of render to avoid recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

function App() {
  return (
    <Elements stripe={stripePromise}>
      <div className="bg-cc-yellow min-h-screen  md:grid bg-repeat bg-[url('images/background_pattern_scissors.svg')]">
        <Marquee />
        <Sidebar />
        <Content />
        <Footer />
      </div>
    </Elements>
  )
}

export default App
