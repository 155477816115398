import { PropsWithChildren } from "react"

type Props = PropsWithChildren &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export const Card: React.FC<Props> = ({ children, className, ...props }) => {
  return (
    <div
      className={`h-48 w-[339px] md:h-80 md:w-[565px] shadow-2xl flex p-2 ${className}`}
      {...props}
    >
      {children}
    </div>
  )
}
