import { Cart } from "@chec/commerce.js/types/cart"
import { CheckoutCapture } from "@chec/commerce.js/types/checkout-capture"
import { LineItem } from "@chec/commerce.js/types/line-item"
import { useState } from "react"
import { commerce } from "../../lib/commerce"
import { FormProps } from "../types"
import { pageCount, pages } from "./checkout-form"
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { CartFooter } from "./contact-details-form"

type PaymentFormProps = Omit<FormProps, "handleFormChanges"> & {
  lineItems: LineItem[]
  checkoutTokenId: string
  setCart: React.Dispatch<React.SetStateAction<Cart | undefined>>
}

export const PaymentForm: React.FC<PaymentFormProps> = ({
  onNext,
  checkoutData,
  lineItems,
  setCart,
  checkoutTokenId,
}) => {
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleCaptureCheckout()
  }

  // capture order
  const handleCaptureCheckout = () => {
    const orderData = {
      line_items: sanitizedLineItems(lineItems),
      customer: {
        firstname: checkoutData.firstName,
        lastname: checkoutData.lastName,
        email: checkoutData.email,
      },
      shipping: {
        name: `${checkoutData.firstName} ${checkoutData.lastName}`,
        street: checkoutData.shippingStreet,
        town_city: checkoutData.shippingCity,
        county_state: checkoutData.shippingStateProvince,
        postal_zip_code: checkoutData.shippingPostalZipCode,
        country: checkoutData.shippingCountry,
      },
      fulfillment: {
        shipping_method: checkoutData.shippingOption!.id,
      },
      payment: {
        gateway: "stripe",
      },
    }

    onCaptureCheckout(checkoutTokenId, orderData)
  }

  const refreshCart = () => {
    commerce.cart
      .refresh()
      .then((newCart) => {
        setCart(newCart)
      })
      .catch((error) => {
        console.error("There was an error refreshing your cart", error)
      })
  }

  const stripe = useStripe()
  const elements = useElements()

  const cardNumElement = elements?.getElement(CardNumberElement)

  const onCaptureCheckout = async (
    checkoutTokenId: string,
    newOrder: CheckoutCapture
  ) => {
    if (!stripe || !cardNumElement) {
      return
    }

    setLoading(true)

    // Create payment method at stripe
    const paymentMethodResponse = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumElement,
    })

    if (paymentMethodResponse.error) {
      // There was some issue with the information that the customer entered into the payment details form.
      alert(paymentMethodResponse.error.message)
      return
    }

    // Capture order in commerce js
    try {
      const order = await commerce.checkout.capture(checkoutTokenId, {
        ...newOrder,
        payment: {
          ...newOrder.payment,
          stripe: { payment_method_id: paymentMethodResponse.paymentMethod.id },
        },
      })

      refreshCart()

      // Store the order in session storage so we can show it again if the
      // user refreshes the page!
      window.sessionStorage.setItem("order_receipt", JSON.stringify(order))

      onNext()
    } catch (error: any) {
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <div className="grid gap-y-2 h-full">
        <h3 className="text-lg font-bold">Payment Detail</h3>
        <form className="flex flex-col justify-between" onSubmit={handleSubmit}>
          <div className="grid md:grid-cols-2 gap-x-6 gap-y-1">
            <div className="md:col-span-2 grid gap-y-1">
              <label className="text-gray-600" htmlFor="cardNum">
                Card Number
              </label>
              <CardNumberElement className="w-full h-9 px-3 py-2 rounded-md border border-cc-lightgray shadow-sm" />
            </div>
            <div className="grid gap-y-1">
              <label className="text-gray-600" htmlFor="expMonth">
                Expiry
              </label>
              <CardExpiryElement className="w-full h-9 px-3 py-2 rounded-md border border-cc-lightgray shadow-sm" />
            </div>
            <div className="grid gap-y-1">
              <label className="text-gray-600" htmlFor="cvc">
                CVC
              </label>
              <CardCvcElement className="w-full h-9 px-3 py-2 rounded-md border border-cc-lightgray shadow-sm" />
            </div>
          </div>
          <CartFooter
            disabled={loading || !stripe || !cardNumElement}
            label={loading ? "Processing payment" : pages[2]}
            index={2}
            pageCount={pageCount}
          />
        </form>
      </div>
    </div>
  )
}

// Sanitize line items
const sanitizedLineItems = (lineItems: LineItem[]) => {
  return lineItems.reduce((data, lineItem) => {
    const item = data
    let variantData = null
    if (lineItem.selected_options.length) {
      variantData = {
        [lineItem.selected_options[0].group_id]:
          lineItem.selected_options[0].option_id,
      }
    }
    // @ts-ignore
    item[lineItem.id] = {
      quantity: lineItem.quantity,
      variants: variantData,
    }
    return item
  }, {})
}
