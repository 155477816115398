type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string
}

export const CartInput: React.FC<InputProps> = ({
  type,
  value,
  onChange,
  placeholder,
  label,
  name,
  ...props
}) => {
  return (
    <input
      id={name}
      name={name}
      type={type}
      className="w-full h-9 px-3 py-2 rounded-md border border-cc-lightgray shadow-sm"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      {...props}
    />
  )
}
