import { Cart } from "@chec/commerce.js/types/cart"
import { useEffect, useState } from "react"
import { commerce } from "../lib/commerce"

export const useGetCart = () => {
  const [cart, setCart] = useState<Cart>()

  const fetchCart = () => {
    commerce.cart
      .retrieve()
      .then((cart) => {
        setCart(cart)
      })
      .catch((error) => {
        console.error("There was an error fetching the cart", error)
      })
  }

  useEffect(() => {
    fetchCart()
  }, [])

  return { fetchCart, setCart, cart }
}
