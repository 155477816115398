import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export const useToast = () => {
  const success = () =>
    toast(
      <div className="flex w-80">
        <p className="w-11 h-11 text-lg">🍳</p>
        <div className="text-sm text-black">
          <p>Wow, that's a nice name!</p>
          <p>
            <strong>PDF generated, ready to be printed</strong>
          </p>
        </div>
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      }
    )
  return {
    success,
  }
}
