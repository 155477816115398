import ReactFastMarquee from "react-fast-marquee"

export const Marquee = () => {
  return (
    <ReactFastMarquee
      gradient={true}
      speed={70}
      gradientWidth="2%"
      className="bg-white h-10"
    >
      {texts.map((text, index) => (
        <p key={index} className="opacity-[50%] pr-60 font-mono">
          {text}
        </p>
      ))}
    </ReactFastMarquee>
  )
}

const texts = [
  "Make them last a little bit longer",
  "Be the coolest guy in the room",
  "No more awkward card exchanges",
  "You're so cool",
  "Lame to cool in less than 5 minutes",
]
