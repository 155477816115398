import rerollIcon from "../images/reroll.svg"
import { useState } from "react"
import { recipes } from "../constants/recipes"
import { Card } from "./common/card"
import { CardButtonContainer } from "./common/card-button-container"
import { CardInput } from "./common/card-input"
import { Circle } from "./common/circle"

const numberOfRecipes = recipes.length

const getRecipe = (currentDishName?: string) => {
  let newIndex = Math.floor(Math.random() * numberOfRecipes)

  while (recipes[newIndex].dishName === currentDishName) {
    newIndex = Math.floor(Math.random() * numberOfRecipes)
  }

  return recipes[newIndex]
}

export const CardBack = () => {
  const [recipe, setRecipe] = useState(getRecipe())
  const { dishName, recipe1, recipe2, countryOfOrigin } = recipe

  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")

  return (
    <div className="grid gap-y-7 font-playfair">
      <Card id="card-back" className="relative bg-cc-yellow">
        <p className="text-md md:text-3xl transform rotate-90 absolute top-0 font-extrabold origin-bottom-left max-w-[200px]">
          {dishName}
        </p>
        <div className="text-center w-full my-auto flex flex-col justify-center gap-y-1 md:gap-y-2 text-2xs md:text-sm ">
          {recipe1.map((recipe, index) => (
            <p key={index}>{recipe}</p>
          ))}
          <CardInput
            className="font-playfair font-bold"
            type="email"
            placeholder="your@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <CardInput
            className="font-playfair font-bold"
            type="text"
            placeholder="+39 3455 027054"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          {recipe2.map((recipe, index) => (
            <p key={index}>{recipe}</p>
          ))}
        </div>
      </Card>

      <CardButtonContainer>
        <Circle>
          <img src={countryOfOrigin} alt="recipe origin country flag" />
        </Circle>
        <button onClick={() => setRecipe(getRecipe(dishName))}>
          <Circle changeOnHover>
            <img
              alt="circle button"
              title="randomise recipe"
              src={rerollIcon}
            />
          </Circle>
        </button>
      </CardButtonContainer>
    </div>
  )
}
