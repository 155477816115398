import { Transition } from "@headlessui/react"
import { Fragment, useState } from "react"
import { ToastContainer } from "react-toastify"
import downloadIcon from "../images/download.svg"
import cartIcon from "../images/shopping-cart.svg"
import { commerce } from "../lib/commerce"
import { useGetCart } from "../queries/use-get-cart"
import { useGetProducts } from "../queries/use-get-products"
import { generateCard } from "../utils/generate-card"
import { Cart } from "./cart"
import { Card } from "./common/card"
import { CardButtonContainer } from "./common/card-button-container"
import { CardInput } from "./common/card-input"
import { Circle } from "./common/circle"
import { useToast } from "./hooks/toast/use-toast"

export const CardFront = () => {
  const { cardcipe } = useGetProducts()
  const { cart, setCart } = useGetCart()

  const [name, setName] = useState("")
  const [tagline, setTagline] = useState("")

  const toast = useToast()

  const handleGeneratePdf = () => {
    generateCard().then(() => {
      toast.success()
    })
  }

  const onAddToCart = (
    productId: string,
    variantId: string,
    variantOptionId: string
  ) => {
    let isInCart

    // Only add to cart if it's not in cart yet
    commerce.cart.contents().then((lineItems) => {
      isInCart = !!lineItems.find((item) => item.product_id === productId)

      if (!isInCart) {
        commerce.cart
          .add(productId, 1, {
            [`${variantId}`]: variantOptionId,
          })
          .then((item) => {
            setCart(item.cart)
          })
          .catch((error) => {
            console.error(
              "There was an error adding the item to the cart",
              error
            )
          })
      }
    })
  }

  const [showCart, setShowCart] = useState(false)

  const handleAddToCart = () => {
    const variant = cardcipe?.variant_groups.find(
      (variant) => variant.name.toLowerCase() === "size"
    )

    const variantOptionId = variant?.options.find((option) =>
      option.name.includes("100")
    )?.id

    if (variant?.id && variantOptionId) {
      onAddToCart(cardcipe!.id, variant?.id, variantOptionId)
      setShowCart(true)
    }
  }

  const handleCloseCart = () => {
    setShowCart(false)
  }

  return (
    <>
      <div className="grid gap-y-7">
        <Card id="card-front" className="bg-white text-center">
          <div className="my-auto">
            <CardInput
              className="font-secular font-normal text-2xl md:text-[40px]"
              type="text"
              title="fill in the blank"
              placeholder="YOUR NAME"
              autoComplete="off"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <CardInput
              className="font-bold text-xs md:text-lg"
              type="text"
              title="fill in the blank"
              placeholder="Not a cook"
              autoComplete="off"
              value={tagline}
              onChange={(e) => setTagline(e.target.value)}
            />
          </div>
        </Card>

        <CardButtonContainer>
          <button onClick={handleGeneratePdf}>
            <Circle changeOnHover>
              <img
                alt="download name card"
                title="generate PDF"
                src={downloadIcon}
              />
            </Circle>
          </button>
          <button onClick={handleAddToCart}>
            <Circle changeOnHover>
              <img alt="add to cart" title="Add to cart" src={cartIcon} />
            </Circle>
          </button>
        </CardButtonContainer>
        <Transition
          as={Fragment}
          show={showCart}
          enter="transition ease-in-out duration-1000"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="duration-1000 transition ease-in-out"
          leaveFrom="translate-x-0 "
          leaveTo="translate-x-full "
        >
          <div className=" w-screen h-screen z-50 fixed top-0 left-0">
            <div className="w-full h-full relative" onClick={handleCloseCart} />
            <Cart setShowCart={setShowCart} cart={cart} setCart={setCart} />
          </div>
        </Transition>
      </div>
      <ToastContainer />
    </>
  )
}
