import { PropsWithChildren } from "react"

type NextButtonProps = PropsWithChildren &
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >

export const NextButton: React.FC<NextButtonProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <button
      className={`bg-black text-white w-full h-12 rounded-md rounded-bl-[33px] text-base font-medium ${className}`}
      type="submit"
      {...props}
    >
      {children}
    </button>
  )
}
