import logoIcon from "../images/cardcipe_logo.svg"
import { CardBack } from "./card-back"
import { CardFront } from "./card-front"

export const Content = () => {
  return (
    <div className="flex items-center justify-center h-[calc(100%-40px-37px)] ">
      <div className="text-left flex flex-col gap-y-5 gap-x-7 px-4 ">
        <div className="hidden md:block">
          <img
            src={logoIcon}
            alt="cardcipe logo"
            width="380px"
            height="110px"
          />
        </div>
        <div className="md:hidden mt-10">
          <img src={logoIcon} alt="cardcipe logo" width="266px" height="77px" />
        </div>

        <div className="flex flex-col md:flex-row gap-7">
          <CardBack />
          <CardFront />
        </div>
      </div>
    </div>
  )
}
